import { useState, useEffect } from 'react';

import { faExclamationCircle, faExternalLink, faCookieBite } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

import { CookiePolicyDialogStyle, CookiePolicyFooterStyle, CookiePolicyModalActionContainer } from './style';

import Button from 'src/components/atoms/button';
import config from 'src/config';
import { CookiePolicyState } from 'src/helpers/constants';
import { useAppData } from 'src/helpers/use';
import { getCookiePolicyAcceptedState, setAcceptCookiePolicy } from 'src/helpers/utils';

type CookiePolicyProps = {
  onStateChange?: (state: CookiePolicyState) => void;
};

const CookiePolicy = ({ onStateChange }: CookiePolicyProps) => {
  const { t } = useTranslation();
  const { appData } = useAppData();
  const [cookiePolicyState, setCookiePolicyState] = useState(CookiePolicyState.Accept);

  useEffect(() => {
    if (appData?.enableCookiePolicy && cookiePolicyState !== CookiePolicyState.ModalClosed) {
      setCookiePolicyState(getCookiePolicyAcceptedState());
    }
  }, [appData]);

  useEffect(() => {
    if (onStateChange) onStateChange(cookiePolicyState);
  }, [cookiePolicyState, onStateChange]);

  const onAccept = () => {
    setAcceptCookiePolicy(true);
    setCookiePolicyState(CookiePolicyState.Accept);
  };

  const notAcceptedPolicy = () => {
    setAcceptCookiePolicy(false);
    setCookiePolicyState(CookiePolicyState.NotAccept);
  };

  const onClose = (event: object, reason: string) => {
    if (reason && reason !== 'backdropClick') {
      notAcceptedPolicy();
    }
  };

  const handleCloseModal = () => {
    setCookiePolicyState(CookiePolicyState.ModalClosed);
  };

  return (
    <>
      <CookiePolicyDialogStyle
        // NOTE: keep modal but not show any situation
        open={false}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        disableEscapeKeyDown
      >
        <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon sx={{ fill: '#BDBDBD', width: 24, height: 24 }} />
          </IconButton>
        </Box>
        <DialogTitle sx={{ mb: '10px' }}>
          <Box sx={{ mb: '24px' }}>
            <FontAwesomeIcon icon={faExclamationCircle} />
          </Box>
          {t('cookie_policy.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t('cookie_policy.content1')}</DialogContentText>
          <DialogContentText>{t('cookie_policy.content2')}</DialogContentText>
          <DialogContentText>{t('cookie_policy.content3')}</DialogContentText>
          <DialogContentText>
            <a href={`${config.IDENTITY_SERVER_DOMAIN}/PrivacyPolicy`} target="_blank" rel="noreferrer">
              {t('cookie_policy.privacy_policy')} <FontAwesomeIcon icon={faExternalLink} />
            </a>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: '8px 24px 24px 24px' }}>
          <CookiePolicyModalActionContainer>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="outlined" onClick={notAcceptedPolicy}>
                    {t('cookie_policy.not_agree')}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Button variant="contained" onClick={onAccept}>
                    {t('cookie_policy.agree_all')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CookiePolicyModalActionContainer>
        </DialogActions>
      </CookiePolicyDialogStyle>
      <CookiePolicyFooterStyle
        display={
          Boolean(appData?.enableCookiePolicy) && cookiePolicyState === CookiePolicyState.Pending ? 'block' : 'none'
        }
      >
        <Box className="container">
          <Grid container height="100%">
            <Grid
              item
              sx={{ display: { xs: 'none', md: 'flex' } }}
              md={2}
              lg={1.5}
              justifyContent="center"
              alignItems="center"
            >
              <FontAwesomeIcon icon={faCookieBite} />
            </Grid>
            <Grid item xs={12} md={6.5} lg={8.5} display="flex" flexDirection="column" justifyContent="center">
              <Typography variant="h4" sx={{ fontSize: { xs: 18, sm: 24 }, mb: { xs: 1, sm: 0 } }}>
                {t('cookie_policy.title')}
              </Typography>
              <Box
                sx={{ flexDirection: { lg: 'row', xs: 'column' }, display: { lg: 'none', xs: 'flex' } }}
                className="text-container"
              >
                <Typography variant="body2" color="textSecondary" fontFamily="Lanna">
                  {t('cookie_policy.content1')} {t('cookie_policy.content2')}
                </Typography>
                <Typography variant="body2" color="textSecondary" fontFamily="Lanna">
                  {t('cookie_policy.content3')}
                </Typography>
                <a href={`${config.IDENTITY_SERVER_DOMAIN}/PrivacyPolicy`} target="_blank" rel="noreferrer">
                  <Typography variant="body2" color="primary.main" fontFamily="Lanna">
                    {t('cookie_policy.privacy_policy')}{' '}
                    <FontAwesomeIcon icon={faExternalLink} style={{ fontSize: 12 }} />
                  </Typography>
                </a>
              </Box>
              <Box sx={{ display: { lg: 'block', xs: 'none' } }} className="text-container">
                <Typography variant="body2" color="textSecondary" fontFamily="Lanna">
                  <span className="policy-content">
                    {t('cookie_policy.content1')} {t('cookie_policy.content2')} {t('cookie_policy.content3')}
                  </span>
                  <Box className="new-line" />
                  <a href={`${config.IDENTITY_SERVER_DOMAIN}/PrivacyPolicy`} target="_blank" rel="noreferrer">
                    {t('cookie_policy.privacy_policy')}{' '}
                    <FontAwesomeIcon icon={faExternalLink} style={{ fontSize: 12 }} />
                  </a>
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={3.5}
              lg={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: { xs: 3, sm: 0 } }}
            >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Button variant="contained" onClick={onAccept} sx={{ width: { sm: 180, xs: '100%' } }}>
                    {t('cookie_policy.agree_all')}
                  </Button>
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Button variant="outlined" onClick={notAcceptedPolicy} sx={{ width: { sm: 180, xs: '100%' } }}>
                    {t('cookie_policy.not_agree')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </CookiePolicyFooterStyle>
    </>
  );
};

export default CookiePolicy;
